


















































import { Component, Vue, Prop } from 'vue-property-decorator';

import PaymentsRepository from '@/repository/modules/payments.repository';
import PaymentModel from '@/models/Payment.model';

import PaymentDetailsComponent from '@/components/Payments/payment-details.vue';
import PaymentInvoiceComponent from '@/components/Payments/payment-invoice.vue';
import PaymentOptionsComponent from '@/components/Payments/payment-options.vue';
import PaymentCreditcardComponent from '@/components/Payments/payment-creditcard.vue';
import { setterDate } from '@/plugins/utils';

@Component({
  components: {
    PaymentDetailsComponent,
    PaymentOptionsComponent,
    PaymentCreditcardComponent,
    PaymentInvoiceComponent,
  },
})
export default class PaymentDetails extends Vue {
  @Prop({ required: true, type: String })
  payment_id!: string;

  paymentData: PaymentModel | null = null;
  loading = true;

  async mounted() {
    await this.getFullDataPayment();
  }

  async getFullDataPayment() {
    this.loading = true;
    this.paymentData = null;
    setTimeout(async () => {
      const paymentModel = await PaymentsRepository.getFullDataPayment(this.payment_id);
      this.paymentData = paymentModel;
      this.loading = false;
    }, 1);
  }

  get colorChip() {
    if (this.paymentData) {
      if (this.paymentData.isSucceeded()) return 'green';
      if (this.paymentData.isError()) return 'red';
      if (this.paymentData.isRefunded()) return 'warning';
      if (this.paymentData.isChargeBack()) return 'red darken-2';
    }
    return 'grey';
  }

  setterDate = setterDate;
}
