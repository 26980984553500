export interface PaymentModelImpl {
  _id: string;
  product_id: string;
  user_id: string;
  chargerback_id: string;
  credit_card_id: string;
  invoice_id: string;
  payment_auth_code_provider: string;
  payment_numeration: string;
  payment_country: string;
  credit_card_country: string;
  payment_type: string;
  request_id: string;
  user_email: string;
  payment_status: string;
  payment_next_action: string;
  payment_client_secret: string;
  payment_amount: number;
  payment_currency: string;
  payment_description: string;
  payment_init_by_user: boolean;
  error_code: string;
  error_message: string;
  payment_getaway: string;
  refunded_at: Date;
  created_at?: Date;
  updated_at?: Date;
}

export class PaymentModelDTO implements PaymentModelImpl {
  _id!: string;
  product_id!: string;
  user_id!: string;
  chargerback_id!: string;
  credit_card_id!: string;
  invoice_id!: string;
  payment_auth_code_provider!: string;
  payment_numeration!: string;
  payment_country!: string;
  credit_card_country!: string;
  payment_type!: string;
  request_id!: string;
  user_email!: string;
  payment_status!: string;
  payment_next_action!: string;
  payment_client_secret!: string;
  payment_amount!: number;
  payment_currency!: string;
  payment_description!: string;
  payment_init_by_user!: boolean;
  error_code!: string;
  error_message!: string;
  payment_getaway!: string;
  refunded_at!: Date;
  created_at!: Date;
  updated_at!: Date;
}

export default class PaymentModel extends PaymentModelDTO {
  constructor(dto: PaymentModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getExternalLinkPayment = () => {
    if (this.payment_getaway == 'stripe')
      return `https://dashboard.stripe.com/payments/${this.payment_auth_code_provider}`;
    else return null;
  };

  isSucceeded = () => this.payment_status == 'succeeded' && !this.chargerback_id;
  isError = () => this.payment_status == 'error';
  isRefunded = () => this.payment_status == 'refunded';
  isChargeBack = () => !!this.chargerback_id;

  hasInvoice = () => !!this.invoice_id;

  paymentStatus = () => (this.isChargeBack() ? 'chargeback' : this.payment_status);
  getPaymentAmount = () => `${this.generatePriceFormat((this.payment_amount / 100).toFixed(2))}`;

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
