


































import { Component, Vue, Prop } from 'vue-property-decorator';
import PaymentModel from '@/models/Payment.model';
import { setterDate } from '@/plugins/utils';
import customersRepository from '@/repository/modules/customers.repository';

@Component({
  components: {},
})
export default class PaymentDetails extends Vue {
  @Prop({ required: true, type: PaymentModel })
  payment!: PaymentModel;
  setterDate = setterDate;

  customerEmail = '';
  customerIP = '';

  async created() {
    const { customer } = await customersRepository.GetCustomerData(this.payment.user_id);
    this.customerIP = customer.user_ip;
    this.customerEmail = customer.user_email;
  }
}
