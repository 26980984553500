
































































import PaymentModel from '@/models/Payment.model';
import paymentsRepository from '@/repository/modules/payments.repository';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class PaymentOptions extends Vue {
  @Prop({ required: true, type: PaymentModel })
  payment!: PaymentModel;
  showRefundDialog = false;
  showMarkAsChargebackDialog = false;

  isLoading = false;
  responseError: string | null = null;

  async refundPayment() {
    this.isLoading = true;
    this.responseError = null;
    const result = await paymentsRepository.refundPayment(this.payment._id);
    this.isLoading = false;
    if (result.isRefunded) {
      this.$emit('reloadPayment');
    } else {
      this.responseError = result.error;
    }
  }

  async chargebackPayment() {
    this.isLoading = true;
    this.responseError = null;
    await paymentsRepository
      .chargebackPayment(this.payment._id)
      .then(() => this.$emit('reloadPayment'))
      .catch((error) => {
        this.responseError = error.response.data.message;
      });
    this.isLoading = false;
  }

  async generateInvoice() {
    await paymentsRepository
      .createInvoiceForPayment(this.payment._id)
      .then(() => this.$emit('reloadPayment'))
      .catch((error) => console.log(error));
  }
}
