export interface CustomerModelImpl {
  _id: string;
  user_uid: string;
  user_email: string;
  user_country: string;
  user_language: string;
  user_signup_provider: string;
  role: string;
  user_ip: string;
  created_at: Date;
  updated_at: Date;
}

export class CustomerModelDTO implements CustomerModelImpl {
  _id = '';
  user_uid = '';
  user_email = '';
  user_country = '';
  user_language = '';
  user_signup_provider = '';
  role = '';
  user_ip = '';
  created_at = new Date();
  updated_at = new Date();
}

export default class CustomerModel extends CustomerModelDTO {
  constructor(dto: Partial<CustomerModelDTO>) {
    super();
    Object.assign(this, dto);
  }

  getCountryAndLanguage() {
    return `${this.user_country}/${this.user_language}`;
  }
}
