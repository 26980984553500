import API from '../repository';
import CustomerModel, { CustomerModelDTO } from '@/models/Customer.model';
import CustomerProfileBillingModel, { CustomerProfileBillingModelDTO } from '@/models/CustomerProfileBilling.model';

const RESOURCE = '/users';

type updateProfileBilling = {
  company_name: string;
  company_vat: string;
  company_address: string;
  company_city: string;
  company_country: string;
};

export interface ResponseListCustomers {
  result: CustomerModelDTO[];
  total_results: number;
  total_pages: number;
  page: number;
  limit: number;
}

interface ResponseGetCustomerData {
  userEntity: CustomerModelDTO;
  userProfileBillingEntity: CustomerProfileBillingModelDTO;
}

export default {
  async getListCustomers({
    query = null,
    page = 0,
    limit = 10,
  }: {
    query: string | null;
    page: number;
    limit: number;
  }) {
    const result: ResponseListCustomers = await API.get(`${RESOURCE}/list`, {
      params: {
        page,
        limit,
        email_to_find: query,
      },
    });
    return {
      customers: result.result.map(c => new CustomerModel(c)),
      total_results: result.total_results,
      total_pages: result.total_pages,
      page: result.page,
      limit: result.limit,
    };
  },

  async GetCustomerData(user_id: string) {
    const { userEntity, userProfileBillingEntity }: ResponseGetCustomerData = await API.get(`${RESOURCE}/${user_id}`);
    return {
      customer: new CustomerModel(userEntity),
      profileBilling: new CustomerProfileBillingModel(userProfileBillingEntity),
      // profilePayments: new CustomerProfilePaymentsModel(userProfilePayments),
    };
  },

  async updateProfileBilling(user_id: string, updateData: updateProfileBilling): Promise<boolean> {
    return await API.put(`${RESOURCE}/${user_id}/profile-billing`, {
      ...updateData,
    });
  },
};
