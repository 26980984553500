export interface CreditCardModelImpl {
  _id: string;
  cardICountryISO3: string;
  psd2_card: string;
  expiry_date: string;
  card_type: string;
  card_brand: string;
  pan: string;
  provider_user: string;
  provider_token: string;
  provider: string;
  user_id: string;
  created_at: Date;
  updated_at: Date;
}

export class CreditCardModelDTO implements CreditCardModelImpl {
  _id!: string;
  cardICountryISO3!: string;
  psd2_card!: string;
  expiry_date!: string;
  card_type!: string;
  card_brand!: string;
  pan!: string;
  provider_user!: string;
  provider_token!: string;
  provider!: string;
  user_id!: string;
  created_at!: Date;
  updated_at!: Date;
}

export default class CreditCardModel extends CreditCardModelDTO {
  constructor(dto: CreditCardModelDTO) {
    super();
    Object.assign(this, dto);
  }
}
