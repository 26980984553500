






































import { Component, Vue, Prop } from 'vue-property-decorator';
import InvoiceModel from '@/models/Invoice.model';
import PaymentModel from '@/models/Payment.model';

import invoicesRepository from '@/repository/modules/invoices.repository';

@Component
export default class ListInvoices extends Vue {
  @Prop({ required: true, type: PaymentModel })
  payment!: PaymentModel;

  loading = true;

  generateInvoiceLoading = false;

  invoice!: InvoiceModel;

  async created() {
    if (this.payment.invoice_id) await this.loadData();
  }

  async loadData() {
    this.loading = true;
    const creditCardResponse = await invoicesRepository.getFullDataInvoice(this.payment.invoice_id);
    this.invoice = creditCardResponse;
    this.loading = false;
  }

  async downloadInvoiceByID() {
    await invoicesRepository.downloadInvoice(this.invoice._id, this.invoice.invoice_numeration);
  }
}
