





















import CreditCardModel from '@/models/CreditCard.model';
import paymentsRepository from '@/repository/modules/payments.repository';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class PaymentCreditcard extends Vue {
  @Prop({ required: true, type: String })
  payment_id!: string;

  creditCardData!: CreditCardModel;
  isLoading = true;

  async created() {
    await this.loadData();
  }

  async loadData() {
    this.isLoading = true;
    const creditCardResponse = await paymentsRepository.getDataPaymentCreditCard(this.payment_id);
    this.creditCardData = creditCardResponse;
    this.isLoading = false;
  }

  get panNumber() {
    if (this.creditCardData.pan.length <= 4) return '****' + this.creditCardData.pan;
    return this.creditCardData.pan;
  }
}
