import API from '../repository';
import PaymentModel, { PaymentModelDTO } from '@/models/Payment.model';
import CreditCardModel, { CreditCardModelDTO } from '@/models/CreditCard.model';
const RESOURCE = '/payments';

export interface ResponseListPayments {
  result: PaymentModelDTO[];
  total_results: number;
  total_pages: number;
  page: number;
  limit: number;
}

export interface ResponseRefund {
  isRefunded: boolean;
  error: string;
}

export default {
  async getListPayments({ query, page = 1, limit = 10 }: { query?: {} | null; page: number; limit: number }) {
    const result: ResponseListPayments = await API.get(`${RESOURCE}/list`, {
      params: {
        page,
        limit,
        ...query,
      },
    });
    return {
      payments: result.result.map(c => new PaymentModel(c)),
      total_results: result.total_results,
      total_pages: result.total_pages,
      page: result.page,
      limit: result.limit,
    };
  },

  async getFullDataPayment(payment_id: string) {
    const response: PaymentModelDTO = await API.get(`${RESOURCE}/${payment_id}/all-data`);
    return new PaymentModel(response);
  },

  async getDataPaymentCreditCard(payment_id: string) {
    const response: CreditCardModelDTO = await API.get(`${RESOURCE}/${payment_id}/credit-card`);
    return new CreditCardModel(response);
  },

  async refundPayment(payment_id: string): Promise<ResponseRefund> {
    return API.put(`${RESOURCE}/${payment_id}/refund`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.resolve(error.response.data));
  },

  async chargebackPayment(payment_id: string) {
    const response: PaymentModelDTO = await API.put(`${RESOURCE}/${payment_id}/chargeback`);
    return new PaymentModel(response);
  },

  async createInvoiceForPayment(payment_id: string): Promise<boolean> {
    return API.post(`${RESOURCE}/${payment_id}/invoice`)
      .then(() => Promise.resolve(true))
      .catch(e => Promise.reject(e.response.data));
  },
};
